import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
export const BodyMain = '#353535';
export const BrandHighlight = '#78EBD6';

export const Wrapper = styled.div`
  width: 87.5%;
  max-width: 1408px;
  margin: 0 auto;
`;

export const Global = createGlobalStyle`
  body {
    font-family: 'Roboto', 'Open Sans', sans-serif;
    color: ${BodyMain};
  }
`;
