import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/seo';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Contact from '../components/contact';
import { BrandHighlight, Wrapper, above, Destyle, Global } from '../components';

import HeaderBG from '../images/header_bg.jpg';
import HelpBG from '../images/how_bg.jpg';
import Logo from '../images/logo.svg';
import CentreOne from '../images/ocean-recovery-centre.jpg';
import CentreTwo from '../images/step1-recovery-spain.jpg';
import CentreThree from '../images/centre_3.jpg';
import IconCheck from '../images/icon_check.svg';

const Header = styled.section`
  background: url(${HeaderBG}) no-repeat top center;
  background-size: cover;
  padding: 50px 0;
  color: #fff;
`;

const Nav = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${above.small`
    grid-template-columns: 1fr 1fr;
    img {
      align-self: center;
    }
  `}
`;

const Button = styled(AnchorLink)`
  background: ${BrandHighlight};
  color: #000;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  padding: 15px 20px;
  border-radius: 50px;
  display: inline-block;
`;

const ExButton = styled.a`
  background: ${BrandHighlight};
  color: #000;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  padding: 15px 20px;
  border-radius: 50px;
  display: inline-block;
`;

const TopCTA = styled.a`
  background: ${BrandHighlight};
  color: #000;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  padding: 15px 20px;
  border-radius: 50px;
  display: inline-block;
  margin-top: 30px;
  ${above.small`
    max-width: 276px;
    justify-self: end;
    margin: 0;
  `}
`;

const HeadWrap = styled.div`
  max-width: 740px;
  p {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 300;
    ${above.small`
      font-size: 24px;
      margin-bottom: 40px;
    `}
  }
`;

const Title = styled.h1`
  font-size: 32px;
  margin: 50px 0 20px;
  ${above.small`
    font-size: 64px;
    margin: 80px 0 40px;
  `}
`;

const Locations = styled.section`
  margin: 40px 0;
  h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 40px;
    ${above.small`
      font-size: 48px;
      margin: 80px 0 40px;
    `}
  }
`;

const Centres = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  gap: 20px;
  ${above.med`
    grid-template-columns: 1fr 1fr 1fr;
  `}
  ${above.large`
    grid-gap: 100px;
    gap: 100px;
  `}
`;

const Centre = styled.section`
  img {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 24px;
    margin-bottom: 15px;
    ${above.large`
      margin: 30px 0 20px;
    `}
  }
  p {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 25px;
    line-height: 1.5;
  }
  a {
    margin-bottom: 40px;
  }
`;

const Help = styled.section`
  background: url(${HelpBG}) no-repeat top center;
  background-size: cover;
  padding: 50px 0;
  color: #fff;
  h2 {
    font-size: 32px;
    margin-bottom: 40px;
    ${above.small`
      font-size: 48px;
      margin: 80px 0 40px;
    `}
  }
  ul {
    list-style: none;
    li {
      background: url(${IconCheck}) no-repeat 0% 50%;
      padding-left: 32px;
      margin-bottom: 10px;
      line-height: 24px;
    }
  }
  a {
    margin: 40px 0 60px;
  }
`;

const Footer = styled.footer`
  text-align: center;
  h2 {
    font-size: 32px;
    margin: 60px 0 30px;
    ${above.small`
      font-size: 48px;
      margin: 80px 0 30px;
    `}
  }
  h3 {
    color: #999;
    font-size: 24px;
    margin: 0 auto 50px;
    max-width: 960px;
    line-height: 1.4;
    ${above.small`
      font-size: 28px;
    `}
  }
  a {
    max-width: 160px;
    margin-bottom: 40px;
  }
  p {
    margin: 0 0 80px;
    padding-top: 80px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query IndexQuery {
        site {
          siteMetadata {
            description
          }
        }
      }
    `}
    render={data => (
      <>
        <div>
          <SEO
            title="Private Treatment"
            keywords={[
              `rehab`,
              `therapy`,
              `counselling`,
              `rehab UK`,
              `rehab Spain`
            ]}
          />
          <Destyle />
          <Global />
          <Header>
            <Wrapper>
              <Nav>
                <Link to="/" title="Private Treatment">
                  <img src={Logo} alt="Private Treatment Logo" />
                </Link>
                <TopCTA href="tel:03300 414 847" title="Call Us">
                  Call Us Free On 03300 414 847
                </TopCTA>
              </Nav>
              <HeadWrap>
                <Title>{data.site.siteMetadata.description}</Title>
                <p>
                  We provide counselling services throughout the UK and Spain,
                  get in touch with us for details on your nearest location. We
                  offer private, qualified experts with highly
                  successful results.
                </p>
                <Contact />
              </HeadWrap>
            </Wrapper>
          </Header>
          <Locations>
            <Wrapper>
              <h2>Featured Locations</h2>
              <Centres>
                <Centre>
                  <img src={CentreThree} alt="Treatment in Watford" />
                  <h3>London Centres</h3>
                  <p>
                    Situated in the beautiful residential area of Cassiobury
                    Park, Watford. Our rehab clinic serves the whole of the UK
                    and covers all areas but is ideal for anyone based in or
                    near London.
                  </p>
                  <ExButton
                    href="#"
                    title="Make An Equiry"
                  >
                    Make An Enquiry
                  </ExButton>
                </Centre>
                <Centre>
                  <img src={CentreOne} alt="Rehab In Spain Photo" />
                  <h3>Northern Centres</h3>
                  <p>
                    Ocean Recovery is the perfect location for your 
                    treatment. Set across three floors, with superb sea views,
                    combining comfort and luxury with an intensive programme of
                    therapy and treatments.
                  </p>
                  <ExButton
                    href="#"
                    title="Make An Equiry"
                  >
                    Make An Enquiry
                  </ExButton>
                </Centre>
                <Centre>
                  <img src={CentreTwo} alt="Counselling in Towcester" />
                  <h3>Spanish Centres</h3>
                  <p>
                    Step One Recovery is a cutting edge, evidence based,
                    life-saving counselling &amp; treatment centre based in a luxury
                    Mediterranean estate setting. We treat a variety of people.
                  </p>
                  <ExButton
                    href="https://step1recovery.com"
                    title="Make An Equiry"
                  >
                    Make An Enquiry
                  </ExButton>
                </Centre>
              </Centres>
            </Wrapper>
          </Locations>
          <Help>
            <Wrapper>
              <h2>How we can help</h2>
              <ul>
                <li>Full Clinical Assessment</li>
                <li>Medication &amp; Treatments</li>
                <li>24/7 Recovery Staffing</li>
                <li>Family Telephone Helpline</li>
                <li>Tailored Recovery Action Plan </li>
                <li>Long Terms And Short Terms Residential Centres</li>
                <li>1 Years Free Aftercare</li>
                <li>Bespoke Day Care Options</li>
                <li>Treatment to Suit All Budgets</li>
              </ul>
              <TopCTA href="tel:03300 414 847" title="Call Us Now">
                Call Us Now On 03300 414 847
              </TopCTA>
            </Wrapper>
          </Help>
          <Footer>
            <Wrapper>
              <h2>Specialised Experts</h2>
              <h3>
                Our Treatment Services combine comfortable &amp; quality
                accommodation; with outstanding therapy programmes that are
                proven to support your recovery and help improve
                all aspects of your life. Accessing our services couldn’t be
                easier… you can call us on <b>03300 414 847</b> or complete the
                contact form and our admissions team will guide you through the
                process!
              </h3>
              <Button href="#___gatsby" title="Make An Equiry">
                Enquire Now
              </Button>
              <p>Copyright &copy; 2019 — Private Treatment</p>
            </Wrapper>
          </Footer>
        </div>
      </>
    )}
  />
);

export default IndexPage;
