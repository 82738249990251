import { css } from 'styled-components';

export const size = {
  small: 700,
  med: 1000,
  large: 1200
};

export const above = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${size[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
