import React from 'react';
import { navigateTo } from 'gatsby-link';
import styled from 'styled-components';
import { BrandHighlight, BodyMain, above } from './';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const Callback = styled.div`
  line-height: 1;
  form {
    display: grid;
    grid-template-columns: 1fr;
    ${above.small`
      grid-template-columns: 1fr 1fr 1fr;
    `}
  }
`;

const FormName = styled.p`
  display: inline-block;
  input {
    background: #fff;
    margin: 40px 0 10px;
    border-radius: 30px;
    padding: 10px 0 10px 20px;
    text-transform: uppercase;
    height: 44px;
    width: 100%;
    font-size: 14px;
    color: ${BodyMain};
    ::placeholder {
      color: rgba(0, 0, 0, .6);
    }
    ${above.small`
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin: 0;
    `}
  }
  span {
    display: none;
  }
`;

const FormNumber = styled.p`
  display: inline-block;
  input {
    background: #fff;
    padding: 10px 0 10px 10px;
    border-radius: 30px;
    margin-bottom: 10px;
    text-transform: uppercase;
    height: 44px;
    width: 100%;
    font-size: 14px;
    color: ${BodyMain};
    ::placeholder {
      color: #bcbcbc;
    }
    ${above.small`
      border-radius: 0;
      margin: 0;
    `}
  }
  span {
    display: none;
  }
`;

const FormSubmit = styled.p`
  display: inline-block;
  ${above.small`
    margin-left: -20px;
  `}
  button {
    background: ${BrandHighlight};
    color: #000;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    padding: 15px 20px;
    border-radius: 50px;
    display: inline-block;
    width: 100%;
    ${above.small`
      width: auto;
    `}
  }
`;

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state
      })
    })
      .then(() => navigateTo(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Callback>
        <form
          name="contact"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out:{' '}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </p>
          <FormName>
            <label>
              <span>Name</span>
              <input
                placeholder="Your Name"
                type="text"
                name="name"
                onChange={this.handleChange}
              />
            </label>
          </FormName>
          <FormNumber>
            <label>
              <span>Phone</span>
              <input
                placeholder="Your Phone Number"
                type="tel"
                name="telephone"
                onChange={this.handleChange}
              />
            </label>
          </FormNumber>
          <FormSubmit>
            <button type="submit">Request Callback</button>
          </FormSubmit>
        </form>
      </Callback>
    );
  }
}
